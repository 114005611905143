import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import * as Sentry from '@sentry/browser';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import Firebase, { FirebaseContext } from './components/Firebase';

const firebase = new Firebase();
const queryClient = new QueryClient();
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7856188097564e2480ebccbaa9325aea@sentry-insync.coreline.hr/7'
  });
}

ReactDOM.render(
  <FirebaseContext.Provider value={firebase}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
