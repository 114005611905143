import React, { lazy, Suspense, useEffect } from 'react';
import './index.css';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Loader } from './components/components/Loader';

const Main = lazy(() => import('./components/Main/MainForm'));
const Maintenance = lazy(() => import('./components/Maintenance/Maintenance'));
const ErrorScreen = lazy(() => import('./components/Error/ErrorScreen'));
const SuccessScreen = lazy(() => import('./components/Success/SuccessPage'));
const SavedQuote = lazy(() => import('./components/SavedQuote/SavedQuote'));
const ReferralScreen = lazy(() => import('./components/Referral/ReferralScreen'));

export const App = () => {
  const history = useHistory();
  useEffect(
    () =>
      history.listen(() => {
        if (history.action === 'POP') {
          history.go(0);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );
  return (
    <Suspense fallback={<Loader message="Please wait..." />}>
      <Switch>
        <Route exact path="/error" component={ErrorScreen} />
        <Route exact path="/success" component={SuccessScreen} />
        <Route exact path="/quoteSaved" component={SavedQuote} />
        <Route exact path="/referral" component={ReferralScreen} />
        <Route exact path="/maintenance" component={Maintenance} />
        <Route path="/" component={Main} />
        <Redirect from="*" to="/" />
      </Switch>
    </Suspense>
  );
};
